<template>
    <div class="app-upload">
        <input type="file" @input="$emit('upload', $event.target.files[0])">
        <AppRegularButton text="загрузить" dark :loading="uploading" :disabled="disabled" @action="$emit('action')" />
        <BaseCloseIcon class="icon" @click="$emit('close')"/>        
    </div>
</template>

<script>
import AppRegularButton from "@/components/buttons/app-regular-btn.vue";
    export default {
        components: {
            AppRegularButton
        },
        props: {
            uploading: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-upload{
        width: 320px;
        height: 300px;
        background-color: $primary;
        border-radius: 0.4em;
        border: 1px solid $accent-dark;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .icon{
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }

    }
</style>